<template>
  <span>
    <span v-if="type === 'bool' || type === 'any'">
      <v-radio-group :value="boolValue" @change="inputValue($event, 'bool')" :mandatory="false" :rules="[(value) => requiredByType('bool', value)]">
        <v-radio :label="$localize('CodeConstant.field.boolTrue')" :value="true"></v-radio>
        <v-radio :label="$localize('CodeConstant.field.boolFalse')" :value="false"></v-radio>
      </v-radio-group>
    </span>
    <span v-if="type === 'text' || type === 'any'">
      <v-text-field :label="$localize('CodeConstant.field.text')" :value="textValue" @change="inputValue($event, 'text')" :rules="[(value) => requiredByType('text', value)]"></v-text-field>
    </span>
    <span v-if="type === 'number' || type === 'any'">
      <v-text-field
        :label="$localize('CodeConstant.field.number')"
        :value="numberValue"
        @change="inputValue($event, 'number')"
        :rules="[(value) => requiredByType('number', value), validation.number]"
      ></v-text-field>
    </span>
  </span>
</template>

<script>
import { required, number } from "@/services/validation";
export default {
  props: {
    value: {},
    type: {
      type: String
    }
  },
  data() {
    return {
      validation: { required, number },
      pickedType: null,
      textValue: null,
      numberValue: null,
      boolValue: null
    };
  },
  watch: {
    value: { 
      immediate: true,
      handler: function(val){
        if(val){
          this.textValue = val.dataType === 'text' ? val.value : null,
          this.numberValue = val.dataType === 'number' ? val.value : null,
          this.boolValue = val.dataType === 'bool' ? val.value : null
        }
      }
    }
  },
  methods: {
    inputValue(value, type) {
      this.pickedType = type;
      this.$emit("input:type", type);
      this.$emit("input:value", value);
    },
    requiredByType(type, value){
      if(!this.pickedType || this.pickedType === type){
        return required(value)
      }
      return true;
    }
  }
};
</script>