<template>
  <span :key="refresh">
    <span v-for="(part, index) in parameterParts" :key="index">
      <template v-if="typeof(part) === 'object'">
        <code-value
          :value="args[part.index]"
          :param="part.param"
          :type-argument="typeArgument(part.index)"
          :text="part.text"
          :code-context-id="codeContextId"
          :variables="variables"
          @input:value="inputValue($event, part.index)"
          @input:type="inputType($event, part.index)"
          @input:delete="deleteValue($event, part.index)"
        ></code-value>
      </template>
      <template v-else>{{part}}</template>
    </span>
  </span>
</template>

<script>
import CodeValue from "./CodeValue";
import { isGuid } from '@/services/validation'
export default {
  components: { CodeValue },
  props: {
    codeContextId: {
      validator: isGuid,
      required: true
    },
    definition: {
      type: Object,
      required: true
    },
    arguments: {
      type: Array
    },
    variables: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      args: this.arguments,

      types: [],

      parameterParts: [],
      refresh: 0
    };
  },
  created() {
    
    let current = 0;
    var params = this.definition.parameters;
    const display = this.definition.display;
    for (let index = 0; index < params.length; index++) {
      const parameterFormat = "{" + index + "}";
      const position = display.indexOf(parameterFormat);
      this.parameterParts.push(display.substr(current, position - current));
      let param = params[index];
      if (param.isGeneric) {
        

        var argument = this.args[index];
        if(argument && argument.dataType){
          this.types[param.genericIndex] =  argument.dataType;
        }
      }
      this.parameterParts.push({
        param: param,
        text: display.substr(position, parameterFormat.length),
        index: index
      });
      current = position + parameterFormat.length;

    }
    this.parameterParts.push(display.substr(current, display.length - current));
    
  },

  methods: {
    inputValue(value, index) {
      this.args[index] = value;
      this.$emit("input:arguments", this.args);
      this.refresh++;
    },
    inputType(value, index) {
      this.types[index] = value;
      this.refresh++;
    },
    deleteValue(value, index){
      this.args[index] = null;
      this.$emit("input:arguments", this.args);
      this.refresh++;
    },
    typeArgument(index) {
      const param = this.definition.parameters[index];
      if (param.isGeneric) {
        var typedArgument = this.types[param.genericIndex];
        if(typedArgument){
          return typedArgument;
        }
      }
      return null;
    }
  }
};
</script>
