<template>
	<span :key="update">
		<v-menu v-model="editing" :close-on-content-click="false" offset-y>
			<template v-slot:activator="{ on }">
				<span v-if="val">
					<span v-if="val.type === 'constant'">
						<v-btn
							small
							v-on="on"
							class="px-1"
							min-width="0"
							outlined
							:color="valColor"
						>
							<v-icon v-if="valPreIcon">{{ valPreIcon }}</v-icon>
							{{ val.value }}
							<v-icon v-if="valPostIcon">{{
								valPostIcon
							}}</v-icon>
						</v-btn>
					</span>
					<span v-else-if="val.type === 'variable'">
						<v-btn
							small
							v-on="on"
							class="px-1"
							min-width="0"
							:color="valColor"
							:title="
								selectedVariableObj
									? selectedVariableObj.key
									: 'unknown'
							"
						>
							<v-icon v-if="valPreIcon">{{ valPreIcon }}</v-icon>
							<template v-if="selectedVariableObj">{{
								selectedVariableObj.name
							}}</template>
							<template v-else
								>Unknown '{{ selectedVariable }}'</template
							>
							<v-icon v-if="valPostIcon">{{
								valPostIcon
							}}</v-icon>
						</v-btn>
					</span>
					<span
						v-else-if="
							val.type === 'function' && functionDefinition
						"
					>
						<span v-if="val.arguments.length > 0">
							<v-btn
								small
								v-on="on"
								class="px-1"
								min-width="0"
								color="grey"
								>(</v-btn
							>
							<code-logic
								:definition="functionDefinition"
								:arguments="val.arguments"
								:code-context-id="codeContextId"
								:variables="variables"
								@input:arguments="onInput($event)"
							/>
							<v-btn
								small
								v-on="on"
								class="px-1"
								min-width="0"
								color="grey"
								>)</v-btn
							>
						</span>
						<span v-else>
							<v-btn
								small
								v-on="on"
								class="px-1"
								min-width="0"
								:color="valColor"
								>{{ functionDefinition.display }}</v-btn
							>
						</span>
					</span>
					<span v-else-if="val.type === 'preValue'">
						<v-btn
							small
							v-on="on"
							class="px-1"
							min-width="0"
							outlined
							:color="valColor"
						>
							<v-icon v-if="valPreIcon">{{ valPreIcon }}</v-icon>
							{{ preValueNameById(val.id) }}
							<v-icon v-if="valPostIcon">{{
								valPostIcon
							}}</v-icon>
						</v-btn>
					</span>
				</span>
				<span v-else>
					<v-btn
						small
						v-on="on"
						class="px-1"
						min-width="0"
						color="error"
						>{{ text }}</v-btn
					>
				</span>
			</template>
			<v-form ref="form">
				<v-card>
					<v-card-title>{{ usedType }}</v-card-title>
					<v-card-text>
						<v-tabs v-model="tab" grow>
							<v-tab
								v-show="
									param.type === 'any' && functions.length > 0
								"
								>{{
									$localize('CodeValue.label.functions')
								}}</v-tab
							>
							<v-tab
								v-show="
									(param.type === 'any' ||
										param.type === 'variable') &&
									allVariables.length > 0
								"
								>{{
									$localize('CodeValue.label.variables')
								}}</v-tab
							>
							<v-tab v-show="param.type === 'any'">{{
								$localize('CodeValue.label.constants')
							}}</v-tab>
							<v-tab
								v-show="
									param.type === 'any' && preValues.length > 0
								"
								>{{
									$localize('CodeValue.label.preValues')
								}}</v-tab
							>
						</v-tabs>
						<v-tabs-items v-model="tab">
							<v-tab-item
								v-show="
									param.type === 'any' && functions.length > 0
								"
								transition="none"
								reverse-transition="none"
							>
								<v-form ref="formFunctions">
									<v-select
										:label="
											$localize(
												'CodeValue.label.functions'
											)
										"
										v-model="selectedFunction"
										:items="functions"
										item-text="name"
										item-value="id"
										:rules="[validation.required]"
									></v-select>
									<v-card-actions>
										<v-btn
											@click="
												save(
													'function',
													'formFunctions'
												)
											"
											>{{
												$localize('form.button.save')
											}}</v-btn
										>
										<v-btn @click="editing = false">{{
											$localize('form.button.cancel')
										}}</v-btn>
									</v-card-actions>
								</v-form>
							</v-tab-item>
							<v-tab-item
								v-show="
									(param.type === 'any' ||
										param.type === 'variable') &&
									allVariables.length > 0
								"
								transition="none"
								reverse-transition="none"
							>
								<v-form ref="formVariables">
									<v-select
										:label="
											$localize(
												'CodeValue.label.variables'
											)
										"
										v-model="selectedVariable"
										:items="allVariables"
										item-text="name"
										item-value="key"
										:rules="[validation.required]"
									></v-select>
									<v-card-actions>
										<v-btn
											@click="
												save(
													'variable',
													'formVariables'
												)
											"
											>{{
												$localize('form.button.save')
											}}</v-btn
										>
										<v-btn @click="editing = false">{{
											$localize('form.button.cancel')
										}}</v-btn>
									</v-card-actions>
								</v-form>
							</v-tab-item>
							<v-tab-item
								v-show="param.type === 'any'"
								transition="none"
								reverse-transition="none"
							>
								<v-form ref="formConstants">
									<code-constant
										:type="usedType"
										:value="val"
										@input:value="
											selectedConstantValue = $event
										"
										@input:type="
											selectedConstantType = $event
										"
									></code-constant>
									<v-card-actions>
										<v-btn
											@click="
												save(
													'constant',
													'formConstants'
												)
											"
											>{{
												$localize('form.button.save')
											}}</v-btn
										>
										<v-btn @click="editing = false">{{
											$localize('form.button.cancel')
										}}</v-btn>
									</v-card-actions>
								</v-form>
							</v-tab-item>
							<v-tab-item
								v-show="
									param.type === 'any' && preValues.length > 0
								"
								transition="none"
								reverse-transition="none"
							>
								<v-form ref="formPreValues">
									<v-select
										v-model="selectedPreValue"
										:items="preValues"
										item-value="key"
										item-text="name"
									></v-select>

									<v-card-actions>
										<v-btn
											@click="
												save(
													'preValue',
													'formPreValues'
												)
											"
											>{{
												$localize('form.button.save')
											}}</v-btn
										>
										<v-btn @click="editing = false">{{
											$localize('form.button.cancel')
										}}</v-btn>
									</v-card-actions>
								</v-form>
							</v-tab-item>
						</v-tabs-items>
						<v-btn @click="deleteValue()">{{
							$localize('form.button.delete')
						}}</v-btn>
					</v-card-text>
				</v-card>
			</v-form>
		</v-menu>
	</span>
</template>

<script>
import {
	getFunctionByReturnType,
	getFunctionDefinitionById,
	createValue,
	getPreValues,
} from '@/services/code'
import CodeConstant from './CodeConstant'
import { required } from '@/services/validation'
import { isGuid } from '@/services/validation'

export default {
	components: { CodeConstant, CodeLogic: () => import('./CodeLogic.vue') },
	props: {
		codeContextId: {
			validator: isGuid,
			required: true,
		},

		value: {
			type: Object,
		},
		param: {
			type: Object,
			required: true,
		},
		typeArgument: {
			type: String,
			default: null,
		},
		text: {
			type: String,
			required: true,
		},
		variables: {
			type: Array,
			required: true,
		},
	},
	computed: {
		usedType() {
			return this.typeArgument || this.param.dataType
		},
		valColor() {
			if (this.val) {
				/*if (this.val.type === 'variable') {
					if (!this.selectedVariableObj) {
						return 'error'
					}
					if (this.val.dataType === 'article') {
						return 'green'
					}
					return 'yellow'
				}*/
				if (this.val.type === 'variable' && !this.selectedVariableObj) {
					return 'error'
				}
				switch (this.val.dataType) {
					case 'article-attribute':
					case 'article-price':
						return 'purple'
					case 'text':
						return 'orange'
					case 'bool':
						if (this.val.type === 'constant') {
							return 'pink'
						}
						return 'pink lighten-2'
					case 'number':
						return 'light-blue lighten-1'
					case 'date':
					case 'datetime':
						return 'light-green'
					default:
						return 'green'
				}
			}
			return 'grey'
		},
		valPreIcon() {
			if (this.val && this.val.variableKey) {
				if (this.val.variableKey.startsWith('system_')) {
					return 'mdi-export'
				}
				if (this.val.variableKey.startsWith('input_')) {
					return 'mdi-import'
				}
			}
			return null
		},
		valPostIcon() {
			if (this.val) {
				switch (this.val.dataType) {
					case 'article':
						return 'mdi-shape-plus'
					case 'article-price':
						return 'mdi-currency-eur'
					case 'article-attribute':
						return 'mdi-numeric'
				}
			}
			return null
		},
	},
	data() {
		return {
			val: this.value,
			selectedVariableObj: '',

			functionDefinition: null,
			editing: false,
			attachTo: null,
			tab: null,
			update: 0,
			functions: getFunctionByReturnType(
				this.typeArgument || this.param.dataType
			),
			readableVariables: [],
			writableVariables: [],
			allVariables: [],

			preValues: [],
			selectedPreValue: null,

			selectedConstantValue: null,
			selectedConstantType: null,
			selectedVariable: null,
			selectedFunction: null,

			validation: { required },
		}
	},
	watch: {
		variables: {
			immediate: true,
			handler(vars) {
				this.allVariables = vars.filter(this.variableFilter)
			},
		},
		value: {
			immediate: true,
			handler(val) {
				if (val) {
					if (val.type === 'function') {
						this.selectedFunction = val.id
						this.tab = 0
					}
					if (val.type === 'variable') {
						this.selectedVariable = val.variableKey
						this.selectedVariableObj = this.variables.filter(
							(x) => x.key === val.variableKey
						)[0]
						if (this.selectedVariableObj === undefined) {
							console.error(
								'Unknown variable: ' + val.variableKey
							)
						}
						this.tab = 1
					}

					if (val.type === 'constant') {
						this.selectedConstantValue = val.value
						this.tab = 2
					}
					if (val.type === 'preValue') {
						this.selectedPreValue = val.id
						this.tab = 3
					}
				}
			},
		},
	},
	async created() {
		if (this.val && this.val.type === 'function') {
			this.functionDefinition = getFunctionDefinitionById(this.val.id)
		} else {
			this.functionDefinition = null
		}

		this.preValues = getPreValues(this.typeArgument || this.param.dataType)
	},
	methods: {
		preValueNameById(id) {
			const preValues = this.preValues.filter((x) => x.key === id)
			if (preValues.length === 1) {
				return preValues[0].name
			}
			return id
		},
		variableFilter(variable) {
			if (this.typeArgument) {
				return this.typeArgument === variable.type
			}
			if (this.param.dataType === 'any') {
				return true
			}
			return variable.type === this.param.dataType
		},
		onInput(values) {
			this.val.arguments = values
			this.$emit('input:value', this.val)
			this.update++
		},
		deleteValue() {
			this.val = null
			this.$emit('input:delete', this.val)
			this.editing = false
			this.update++
		},
		save(type, form) {
			if (!this.$refs[form].validate()) {
				return
			}
			var value
			var dataType
			if (type === 'variable') {
				value = this.selectedVariable
				var variable = this.variables.filter((x) => x.key === value)[0]
				dataType = variable.type
			} else if (type === 'constant') {
				value = this.selectedConstantValue
				dataType = this.selectedConstantType
			} else if (type === 'function') {
				if (!this.selectedFunction) {
					return
				}
				value = this.selectedFunction
				this.functionDefinition = getFunctionDefinitionById(value)
				dataType = this.functionDefinition.return
			} else if (type === 'preValue') {
				value = this.selectedPreValue
				dataType = this.typeArgument || this.param.dataType
			}
			this.val = createValue(type, value, dataType)
			this.$emit('input:value', this.val)
			if (this.param.isGeneric) {
				this.$emit('input:type', dataType)
			}
			this.editing = false
			this.update++
		},
	},
}
</script>
